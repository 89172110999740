<template>
  <h1 class="text-h1 secondary--text text-uppercase font-weight-bold text--darken-4">
    <slot/>
  </h1>
</template>

<script>
export default {
  name: 'KTitle',
};
</script>
